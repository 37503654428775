<template>
  <div v-if="loading">
    <sl-loading class="size-18" content="" />
  </div>
  <div v-else-if="!bind">
    <div v-if="!pessoas || !pessoas.length" class="m-t">
      Nenhum registro de sócio lançado.
    </div>
    <div v-for="(pessoa, key) in pessoas" :key="key" class="m-t">
      <div v-if="!pessoa.bind">
        <e-row mr>
          <e-col style="max-width: 200px">
            <erp-s-field label="Tipo">
              <tipo-papel-select v-model="pessoa.papel.typePaper" :limit="['socio', 'representante-legal']" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Pessoa">
              <fin-pessoa-input full placeholder="Selecione uma pessoa" @input="(v) => definePessoa(v, pessoa)" :value="pessoa.fakePessoa" :router="router || $router"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 150px">
            <erp-s-field label="CPF/CNPJ">
              <erp-input size="2" v-model="pessoa.document" v-mask="pessoa.type == 1 ? '###.###.###-##' : '##.###.###/####-##'" disable />
            </erp-s-field>
          </e-col>
          <e-col v-if="pessoa && pessoa.id" style="max-width: 150px">
            <erp-s-field label="Telefone">
              <erp-input size="2" v-model="pessoa.phoneNumbers[0].phoneNumber" v-mask="pessoa.phoneNumbers[0].phoneNumber && pessoa.phoneNumbers[0].phoneNumber.length === 11 ? '(##) # ####-####' : ''" disable />
            </erp-s-field>
          </e-col>
          <e-col v-else style="max-width: 150px">
            <erp-s-field label="Telefone">
              <erp-input size="2" v-model="fake" disable />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row v-if="pessoa && pessoa.id" mr>
          <e-col style="max-width: 80px">
            <erp-s-field label="CEP">
              <erp-input size="2" v-model="pessoa.addresses[0].zip" disable />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 300px">
            <erp-s-field label="Endereço">
              <erp-input size="2" v-model="pessoa.addresses[0].address" disable />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 60px">
            <erp-s-field label="Número">
              <erp-input size="2" v-model="pessoa.addresses[0].number" disable />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 80px">
            <erp-s-field label="Complemento">
              <erp-input size="2" v-model="pessoa.addresses[0].complement" disable />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 140px">
            <erp-s-field label="Bairro">
              <erp-input size="2" v-model="pessoa.addresses[0].district" disable />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 140px">
            <erp-s-field label="Cidade">
              <erp-input size="2" v-model="pessoa.addresses[0].city" disable />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 50px">
            <erp-s-field label="UF">
              <erp-input size="2" v-model="pessoa.addresses[0].state" disable />
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
      <div v-if="startRemove">
        <a class="text-negative" @click="remover(pessoa)">Remover registro</a>
      </div>
      <div v-if="pessoa.isNew">
        <u-btn @click="salvar(pessoa)" class="full-width" color="positive" no-caps label="Salvar alterações de novo sócio/representante" />
      </div>
      <div style="border-top: #cccccc 1px dotted; margin-top: 12px; padding-bottom: 12px"></div>
    </div>

    <div class="m-t">
      <u-btn @click="novo" class="" color="grey-8" no-caps label="Adicionar Sócio / Representante" />
      <u-btn v-if="pessoas && pessoas.length" @click="startRemove = !startRemove" class="m-l" color="negative" flat no-caps size="sm" label="Remover algum registro" />
    </div>

    <div class="m-t">
      <div class="sl-alert bg-blue-1 m-b-xs" style="line-height: 1.1">Nota Versão 3.7.0: A partir desta versão é possível relacionar um ou mais sócios no sistema. Recomendamos que acesse <u>Configurações &raquo; Cliente</u>, e na opção <strong>Bloquear cadastro de pessoa jurídica por não logados</strong> selecione <strong>Bloquear, liberar cadastro somente por representante legal</strong> para permitir o cadastro de pessoa jurídica somente por sócios ou representante legal.</div>
      <div class="sl-alert">A partir da versão 3.7.0 do software, alteramos a arquitetura do cadastro de pessoa jurídica, permitindo a relação de um ou mais sócios. Caso o cadastro for anterior à esta mudança, use os dados abaixo:</div>
      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field label="Nome do proprietário">
            {{arrematante.pjProprietarioNome}}
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="CPF do proprietário">
            {{arrematante.pjProprietarioCpf|formataCpfCnpj2}}
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="RG">
            {{arrematante.pjProprietarioRg}}
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Telefone">
            {{arrematante.pjProprietarioTelefoneComercial}}
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
  </div>
</template>

<script>
import {ErpInput} from "uloc-vue-plugin-erp"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import FinPessoaInput from "components/financeiro/components/input/PessoaInput.vue";
import {mockEndereco} from "components/pessoa/mock/endereco";
import {mockTelefone} from "components/pessoa/mock/telefone";
import {removeRepresentante, representantes, saveRepresentante} from "@/domain/pessoa/services"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField";
import TipoPapelSelect from "components/pessoa/components/include/TipoPapelSelect.vue";
import SlLoading from "components/layout/components/Loading.vue";

const mockPessoa = {
  bind: false,
  papel: {
    typePaper: null
  },
  fakePessoa: null,
  id: null,
  type: 1,
  document: null,
  addresses: [JSON.parse(JSON.stringify(mockEndereco))],
  phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone))]
}

export default {
  name: "Socios",
  inject: ['arrematanteContainer'],
  components: {
    SlLoading,
    TipoPapelSelect,
    ErpInput,
    ErpSField,
    FinPessoaInput,
    ERow,
    ECol
  },
  mounted() {
    this.load()
  },
  mixins: [],
  data() {
    return {
      loading: false,
      pessoas: [],
      bind: false,
      fake: null,
      startRemove: false
    }
  },
  watch: {
  },
  computed: {
    arrematante () {
      return this.arrematanteContainer.arrematante
    },
    router () {
      return this.arrematanteContainer.router
    }
  },
  methods: {
    load () {
      this.loading = true
      representantes(this.arrematante.pessoa.id)
          .then(response => {
            let data = response.data.result
            if (!data || !Array.isArray(data)) {
              data = []
            } else {
              data = data.map(pessoa => {
                pessoa.fakePessoa = pessoa
                if (!pessoa.addresses || typeof pessoa.addresses[0] === 'undefined' || (Array.isArray(pessoa.addresses) && !pessoa.addresses.length)) {
                  pessoa.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
                }
                if (!pessoa.phoneNumbers || typeof pessoa.phoneNumbers[0] === 'undefined' || (Array.isArray(pessoa.phoneNumbers) && !pessoa.phoneNumbers.length)) {
                  pessoa.phoneNumbers = [JSON.parse(JSON.stringify(mockTelefone))]
                }
                let paper = null
                pessoa.papers.map(p => {
                  if (p.superPerson && p.superPerson.id === this.arrematante.pessoa.id) {
                    paper = p
                  }
                })
                if (paper) {
                  pessoa.papel = paper
                }
                console.log(pessoa)
                return pessoa
              })
            }
            this.pessoas = data
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    definePessoa (p, n) {
      if (!p || !p.id) return
      Object.assign(n, {}, p)
      n.fakePessoa = p
      if (!n.addresses || typeof n.addresses[0] === 'undefined') {
        n.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
      }
      console.log(p)
      if (!n.phoneNumbers || typeof n.phoneNumbers[0] === 'undefined') {
        n.phoneNumbers = [JSON.parse(JSON.stringify(mockTelefone))]
      }
      n.bind = true
      this.$nextTick(() => {
        n.bind = false
      })
    },
    novo () {
      if (!this.pessoas || !Array.isArray(this.pessoas)) {
        this.pessoas = []
      }
      let mock = JSON.parse(JSON.stringify(mockPessoa))
      mock.isNew = true
      this.pessoas.push(mock)
    },
    remover (n) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? ', 'Sim')
          .then(() => {
            this.bind = true
            this.$nextTick(() => {
              this.pessoas.splice(this.pessoas.indexOf(n), 1)
              this.$nextTick(() => {
                this.bind = false
                removeRepresentante(this.arrematante.pessoa.id, n.id)
                    .then(response => {
                      this.dg('Excluído com sucesso')
                    })
                    .catch(error => {
                      this.alertApiError(error)
                    })
              })
            })
          })
          .catch(() => {})
    },
    salvar (p) {
      if (!p.papel || !p.papel.typePaper) {
        alert('Informe o tipo do papel')
        return
      }
      if (!p.fakePessoa) {
        alert('Informe uma pessoa')
        return
      }
      saveRepresentante(this.arrematante.pessoa.id, p.fakePessoa.id, p.papel.typePaper.id)
          .then(response => {
            p.isNew = false
            this.dg('Salvo com sucesso!')
          })
          .catch(error => {
            this.alertApiError(error)
          })
      console.log(p)
    }
  }
}
</script>
